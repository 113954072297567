/* Built In Imports */
import { useContext, useEffect, useRef, useState } from 'react';
// import { NonceProvider } from 'react-select';

/* External Imports */
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AspectRatio,
  Box,
  Button,
  Image,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import parser from 'html-react-parser';

/* Internal Imports */
/* Components */
import config from '@config';
// import WrapLayout from '@Layout/WrapLayout';
import StructuredTextContent from '@components/UI/StructuredTextContent';
import HeadComponent from '@components/Utility/HeadComponent';
import Fireup from '@landing_pages/shivanga-sadhana/Fireup';
import HighlightsContent from '@landing_pages/shivanga-sadhana/Highlights';
import SadhanaContent from '@landing_pages/shivanga-sadhana/Sadhanadetail';
import ShivangaBanner from '@landing_pages/shivanga-sadhana/ShivagnaBanner';
import ShivangaBenefits from '@landing_pages/shivanga-sadhana/ShivangaBenefits';
import ShivangaContactus from '@landing_pages/shivanga-sadhana/ShivangaContactus';
import ShivangaFaq from '@landing_pages/shivanga-sadhana/ShivangaFaq';
import ShivangaRegistration from '@landing_pages/shivanga-sadhana/ShivangaRegistration';
import ShivangaStickyButton from '@landing_pages/shivanga-sadhana/ShivangaStickybtn';
import ShivangaTestimonials from '@landing_pages/shivanga-sadhana/ShivangaTestimonials';
import SignificanceVellagiri from '@landing_pages/shivanga-sadhana/SignificanceVellagiri';
import WhatsShivanga from '@landing_pages/shivanga-sadhana/WhatisShivanga';
import { AuthContext } from '@store/auth-context';

/* Services */
import { getLocale } from '@components/Utility/Shared/SharedService';
import { nanoid } from 'nanoid';
import {
  fetchFooterData,
  fetchHeaderMenuData,
  fetchHeaderTopMenuData,
  getCurrentRegion,
} from 'services/commonService';
import {
  fetchShivangaPageData,
  getLandingPaths,
} from 'services/shivangaService';

/* Styles */
// import classes from '@styles/pages/SadhguruPage.module.css';

/**
 * @param {string} region - Current User Region
 * @param {string} language - Current User Language
 * @param {string} pageData - Shivanga Sadhana Landing Page Data
 * @returns
 */
export default function Page({
  headerMenuData,
  region,
  language,
  footerData,
  pageData,
}) {
  const pageDetailData = pageData.body[0].value;
  const [currentUserRegion, setCurrentUserRegion] = useState(region);
  const [currentRegion, setCurrentRegion] = useState('');
  const [tabNumber, setTabNumber] = useState('1');
  const [isMobile, setIsMobile] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  let userRegion = '';
  if (region != 'in' && currentRegion != 'IN') {
    userRegion = '&country_code=' + currentRegion;
  } else {
    userRegion = '';
  }
  const tabToggle = e => {
    const tabActive = e.target.id.replace(/\D/g, '');
    setTabNumber(tabActive);
    let matches = document?.querySelectorAll('#sadhana-details-tabs .inactive');

    matches.forEach(function (item) {
      item.classList.remove('active');
      e.target.classList.add('active');
    });
    const anchorName = '#' + e.target.id + 'c';
    window.location.href = anchorName;
  };

  useEffect(() => {
    localData(region, language);
  }, [region, language]);

  const localData = async (region, language) => {
    const reg = await getCurrentRegion();
    if (reg?.country) {
      setCurrentRegion(reg.country);
    }
  };
  console.log('ipregion', currentRegion);

  const checkForDevice = () => {
    if (typeof window !== 'undefined') {
      // if (window.innerWidth < 767.98) {
      setIsMobile(true);
      // }
    }
  };

  const upcoming = () => {
    const tab1 = document.querySelector('#tab-1');
    const tab2 = document.querySelector('#tab-2');
    const tab3 = document.querySelector('#tab-3');
    // const tab4 = document.querySelector('#tab-4');
    const tab_content1 = document.querySelector('#tab-content-1');
    const tab_content2 = document.querySelector('#tab-content-2');
    const tab_content3 = document.querySelector('#tab-content-3');
    // const tab_content4 = document.querySelector('#tab-content-4');
    if (window.pageYOffset >= tab_content1?.offsetTop) {
      tab2?.classList.remove('active');
      tab3?.classList.remove('active');
      // tab4.classList.remove('active');
      tab1?.classList.add('active');
      if (window.pageYOffset >= tab_content2.offsetTop) {
        tab1?.classList.remove('active');
        tab3?.classList.remove('active');
        // tab4.classList.remove('active');
        tab2?.classList.add('active');
        if (window.pageYOffset >= tab_content3.offsetTop) {
          tab1?.classList.remove('active');
          tab2?.classList.remove('active');
          // tab4.classList.remove('active');
          tab3?.classList.add('active');
          // if (window.pageYOffset >= tab_content4.offsetTop) {
          //   tab1?.classList.remove('active');
          //   tab2?.classList.remove('active');
          //   tab3?.classList.remove('active');
          //   tab4.classList.add('active');
          // }
        }
      }
    }
  };

  useEffect(() => {
    checkForDevice();
    // if (isMobile) {
    // window.addEventListener('scroll', upcoming);
    // } else {
    window.addEventListener('scroll', upcoming);
    // }
  });
  const Banner = pageData => {
    const banner = pageData?.find(el => el.sectionId == 'HeaderBanner');
    const bannerData = banner?.sectionContent;
    return (
      bannerData && (
        <>
          <Box>
            <Box
              background={{
                base: `url('${bannerData[0]?.mobileImage?.url}') no-repeat #000000 center -50px !important`,
                lg: `url('${bannerData[0]?.image?.url}') no-repeat #000000 center !important`,
                md: `url('${bannerData[0]?.image?.url}') no-repeat #000000 center !important`,
              }}
              w={{ base: '100%' }}
              position="relative"
              textAlign="center"
              minHeight={{ base: '366px', sm: '719px' }}
              backgroundSize="contain"
              backgroundPosition="center !important"
              backgroundColor="#000000"
            >
              <Box
                w={{ base: 'auto', xl: '1360px' }}
                m={{ base: '0 auto' }}
                p={{ lg: '35px 5% 0' }}
              >
                <Box display={{ base: 'none', lg: 'inherit' }} textAlign="left">
                  <Image
                    loading="lazy"
                    src={bannerData[1]?.image?.url}
                    title={bannerData[1]?.image?.title}
                    alt={bannerData[1]?.image?.alt}
                  />
                </Box>
                <Box>
                  <Text
                    as="h1"
                    fontFamily="MerriweatherBold"
                    color="#FFA735"
                    fontSize={{ lg: '52px', md: '40px', base: '28px' }}
                    fontWeight="700"
                    pt={{ base: '60px', lg: '20px', md: '20px' }}
                  >
                    {bannerData[2]?.titleText}
                  </Text>
                  <Text
                    as="h3"
                    fontFamily="MerriweatherBold"
                    color="#FFFFFF"
                    fontSize={{ lg: '36px', md: '28px', base: '20px' }}
                    fontWeight="700"
                  >
                    {bannerData[2]?.titleSubtext}
                  </Text>
                  <Text
                    fontFamily="FedraSansStd-book"
                    color="#F5E3B5"
                    fontSize="22px"
                  >
                    {bannerData[3]?.text}
                  </Text>
                  <Box m="40px 0 0 0" className="shivanga-text">
                    <Link
                      onClick={onOpen}
                      cursor="pointer"
                      _focus={{ outline: '0' }}
                    >
                      <Text
                        fontFamily="FedraSansStd-book"
                        color="#FFFFFF"
                        fontSize="20px"
                        textDecoration="underline"
                      >
                        {parser(bannerData[4]?.htmlContent || '')}
                      </Text>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            background="#000000"
            p={{ lg: '20px 15px', base: '20px 15px 40px 15px' }}
          >
            <Box
              w={{ base: 'auto', lg: '750px' }}
              margin="0 auto"
              textAlign="center"
            >
              <Text
                color="#C1C1C1"
                fontSize="16px"
                textAlign={{ base: 'left', sm: 'center' }}
                margin="0 0 20px 0"
                lineHeight="28px"
                className="bannerparatext"
                fontFamily="OpenSansRegular"
              >
                {StructuredTextContent(bannerData[5]?.body)}
              </Text>
              <Box
                display="flex"
                flexDirection={{ base: 'column', sm: 'row' }}
                justifyContent="center"
                fontFamily="OpenSansRegular"
              >
                {region === 'in' ? (
                  <>
                    <Box
                      w={{ base: 'auto', sm: '30%' }}
                      m={{ base: '0 auto', sm: '0' }}
                    >
                      <Text color="#FFA735" fontSize="14px" mb="20px">
                        {StructuredTextContent(bannerData[6]?.body)}
                      </Text>
                      {bannerData[7]?.linkUrl != '' && (
                        <Button
                          background="#CA4E2A"
                          border="none"
                          borderRadius="3px"
                          fontSize="16px"
                          _hover={{
                            bg: '#000054',
                            border: 'none',
                          }}
                          w={{ base: 'auto', lg: '100%' }}
                          m="10px 0 0"
                          fontFamily="OpenSansRegular"
                        >
                          <Link
                            href={bannerData[7]?.linkUrl + userRegion}
                            target="_blank"
                            p="18px 34px 18px 34px"
                            textDecoration="none"
                            _focus={{ outline: '0' }}
                            _hover={{ textDecoration: 'none' }}
                          >
                            {bannerData[7]?.buttonText}
                          </Link>
                        </Button>
                      )}
                    </Box>
                    {/* <Box w="1px" p="5px 0 23px 0" m="0 0 0 15px">
                    <Box w="1px" background="#FFA735" height="100%"></Box>
                  </Box>
                  <Box w={{ base: 'auto', sm: '33%' }}>
                    <Text
                      color="#FFFFFF"
                      fontSize="14px"
                      w={{ sm: '90%' }}
                      m={{ base: '0 auto 10px', sm: '0 auto' }}
                    >
                      {StructuredTextContent(bannerData[7]?.body)}
                    </Text>
                    <Button
                      m={{ sm: '32px 0 0 0' }}
                      border="1px solid #fff"
                      borderRadius="3px"
                      fontSize="16px"
                      p="18px 34px 18px 34px"
                      fontFamily="OpenSansRegular"
                      color="#ffffff"
                      background="transparent"
                      _hover={{
                        background: 'transparent',
                        textDecor: 'none',
                      }}
                    >
                      <Link href={bannerData[8]?.linkUrl} target="_blank">
                        {bannerData[8]?.buttonText}
                      </Link>
                    </Button>
                    <Text
                      as="p"
                      color="#FFA735"
                      fontSize="14px"
                      m={{ base: '10px 0 0', lg: '10px 0 0' }}
                    >
                      {bannerData[9]?.text}
                    </Text>
                  </Box> */}
                  </>
                ) : (
                  <Box color="#ffffff" m="20px 10px" textAlign="center">
                    {region != 'in' && (
                      <>
                        {bannerData[7]?.linkUrl != '' && (
                          <Button
                            background="#CA4E2A"
                            border="none"
                            borderRadius="3px"
                            fontSize="16px"
                            _hover={{
                              bg: '#000054',
                              border: 'none',
                            }}
                            w={{ base: 'auto', lg: '100%' }}
                            m="10px 0 0"
                            fontFamily="OpenSansRegular"
                          >
                            <Link
                              href={bannerData[7]?.linkUrl + userRegion}
                              target="_blank"
                              p="18px 34px 18px 34px"
                              textDecoration="none"
                              _focus={{ outline: '0' }}
                              _hover={{ textDecoration: 'none' }}
                            >
                              {bannerData[7]?.buttonText}
                            </Link>
                          </Button>
                        )}
                      </>
                    )}
                    {/* {bannerData[6]?.buttonText} */}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay bgColor="black" />
            <ModalContent bgColor="transparent" maxW="720px">
              <ModalCloseButton
                top={{ base: '-34px', md: '-38px', lg: '-20px' }}
                right={{ base: '12px', md: '8px', lg: '-70px' }}
                color="#fff"
                fontSize={{ base: '18px', md: '24px', lg: '24px' }}
                _focus={{
                  boxShadow: 'none',
                }}
              />
              <ModalBody
                p="0"
                m={{ base: '0 10px', md: '0', lg: '20px 0 0 0' }}
              >
                <AspectRatio>
                  <iframe
                    src="https://www.youtube.com/embed/Sizx3TtC8L0"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ 'z-index': '1' }}
                    width="auto"
                  ></iframe>
                </AspectRatio>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )
    );
  };
  const WhatisShivanga = pageData => {
    const whatis = pageData?.find(el => el.sectionId == 'Section_1');
    const whatisData = whatis?.sectionContent;
    return (
      <>
        <Box background="#00052F">
          <Box
            display={{ base: 'flex' }}
            flexDirection={{ base: 'column-reverse', sm: 'row' }}
            w={{ base: 'auto', sm: '90%', xl: '1300px' }}
            m={{ base: 'unset', sm: '0 auto' }}
            p={{ base: '0 0 25px 0', sm: 'unset' }}
            className="whatisshivanga"
          >
            <Box
              w={{ base: '', sm: '50%', lg: '38%' }}
              margin={{
                base: '0 16px 0',
                sm: '40px 10% 0 0',
                lg: '85px 10% 0 2.5%',
              }}
            >
              <Text
                as="h2"
                color="#FFA735"
                fontSize={{ base: '30px', lg: '36px' }}
                fontFamily="MerriweatherBold"
                m="0 0 25px 0"
              >
                {whatisData[0]?.titleText}
              </Text>
              <Text
                color="#FFFFFF"
                fontSize={{ base: '17px', lg: '22px' }}
                fontFamily="OpenSansRegular"
              >
                {StructuredTextContent(whatisData[2]?.body)}
              </Text>
            </Box>
            <Box
              m={{ base: '40px 16px 39px', sm: '48px 0 43px 0' }}
              w={{ base: 'unset', sm: '40%', lg: 'unset' }}
            >
              <Image
                loading="lazy"
                src={whatisData[1]?.image?.url}
                title={whatisData[1]?.image?.title}
                alt={whatisData[1]?.image?.alt}
              />
            </Box>
          </Box>
        </Box>
        <Box background="#FFEBC7" p={{ base: '', sm: '53px 0 5px 0' }}>
          <Box w={{ base: 'auto', lg: '80%' }} m={{ base: '', lg: '0 auto' }}>
            <Text
              color="#000000"
              fontSize="16px"
              fontFamily="OpenSansRegular"
              m={{ sm: '0 auto 30px' }}
              p={{ base: '30px 0 40px 17px', sm: 'unset' }}
              textAlign={{ base: 'left', sm: 'center' }}
              w={{ base: 'unset', sm: '70%' }}
              className="shivanga-text whatisdata"
            >
              {StructuredTextContent(whatisData[3]?.body)}
            </Text>
          </Box>
        </Box>
      </>
    );
  };
  const Benefits = pageData => {
    const benefits = pageData?.find(el => el.sectionId == 'Benefits');
    const benefitsData = benefits?.sectionContent;
    return (
      <>
        <Box bgColor="#0D0D0D">
          <Box
            background={`url(${config.imgPath}/d/46272/1647929002-benfits-of-shivanga-bg.jpg) no-repeat #0D0D0D`}
            // background="url(https://images.sadhguru.org/d/46272/1647929002-benfits-of-shivanga-bg.jpg) no-repeat #0D0D0D"
            backgroundSize="cover"
          >
            <Box background="linear-gradient(284deg, rgba(13, 13, 13, 0) 0%, #0D0D0D 100%)">
              <Box
                w={{ base: '85%', sm: '50%', xl: '85%' }}
                m={{ base: '63px auto' }}
                p={{ base: '55px 0' }}
                textAlign={{ base: 'center' }}
              >
                <Text
                  as="h2"
                  color="#FCF6E2"
                  fontFamily="MerriweatherBold"
                  fontSize={{ base: '30px', lg: '36px' }}
                  margin={{ base: '0 0 25px 0' }}
                >
                  {benefitsData && benefitsData[0]?.title[0]?.titleText}
                </Text>
                <Image
                  loading="lazy"
                  alt="separate_border"
                  title="separate_border"
                  src={`${config.imgPath}/d/46272/1647429933-shivang_separate_border.png`}
                  m={{ base: '0 0 55px 0' }}
                />
                <Box
                  display={{ base: 'flex' }}
                  flexDirection={{ base: 'column', sm: 'row' }}
                  justifyContent={{ base: 'space-between' }}
                  flexWrap={{ base: 'unset', sm: 'wrap', xl: 'unset' }}
                  m="0 auto"
                >
                  {benefitsData &&
                    benefitsData[0]?.benefits.map(data => {
                      return (
                        <Box
                          w={{ base: '100%', sm: '48%', lg: '50%', xl: '25%' }}
                          p="0 10px"
                          key={nanoid}
                        >
                          <Image
                            loading="lazy"
                            alt={data?.image?.alt}
                            title={data?.image?.title}
                            src={data?.image?.url}
                            m={{ base: '0 0 22px 0' }}
                          />
                          <Text
                            as="h6"
                            color="#FCF6E2"
                            fontFamily="OpenSansRegular"
                            fontSize={{ base: '16px' }}
                            m={{ base: '0 auto 35px auto', lg: '0 auto 15px' }}
                            w={{ base: '80%', xl: 'auto' }}
                          >
                            {data?.text}
                          </Text>
                        </Box>
                      );
                    })}
                </Box>
                {region === 'in' ? (
                  <>
                    {benefitsData[1]?.linkUrl != '' && (
                      <Button
                        background="#CA4E2A"
                        border="none"
                        borderRadius="3px"
                        fontSize="16px"
                        _hover={{
                          border: 'none',
                          textDecor: 'none',
                          bg: '#000054',
                        }}
                        w={{ base: 'auto' }}
                        m="0px auto 0"
                        display="flex"
                        fontFamily="OpenSansRegular"
                      >
                        <Link
                          href={benefitsData[1]?.linkUrl + userRegion}
                          target="_blank"
                          textDecoration="none"
                          p="18px 34px 18px 34px"
                          _focus={{ outline: '0' }}
                          _hover={{ textDecoration: 'none' }}
                        >
                          {benefitsData[1]?.buttonText}
                        </Link>
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <Box color="#ffffff" m="20px 10px" textAlign="center">
                      {region != 'in' && (
                        <>
                          {benefitsData[1]?.linkUrl != '' && (
                            <Button
                              background="#CA4E2A"
                              border="none"
                              borderRadius="3px"
                              fontSize="16px"
                              _hover={{
                                border: 'none',
                                textDecor: 'none',
                                bg: '#000054',
                              }}
                              w={{ base: 'auto' }}
                              m="0px auto 0"
                              display="flex"
                              fontFamily="OpenSansRegular"
                            >
                              <Link
                                href={benefitsData[1]?.linkUrl + userRegion}
                                target="_blank"
                                textDecoration="none"
                                p="18px 34px 18px 34px"
                                _focus={{ outline: '0' }}
                                _hover={{ textDecoration: 'none' }}
                              >
                                {benefitsData[1]?.buttonText}
                              </Link>
                            </Button>
                          )}
                        </>
                      )}
                      {/* {benefitsData[1]?.buttonText} */}
                    </Box>
                  </>
                )}
                {benefitsData[2]?.text != '' && (
                  <Text
                    color="#FFA735"
                    fontSize="16px"
                    fontFamily="OpenSansRegular"
                    m="20px 0 0 0"
                  >
                    {benefitsData[2]?.text}
                  </Text>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };
  const Testimonialsection = pageData => {
    const testimonial = pageData?.find(el => el.sectionId == 'Testimonial');
    const testimonialdata = testimonial?.sectionContent;
    return (
      <>
        <Box w={{ base: 'auto' }} m={{ base: '', lg: '0 auto' }}>
          <Box
            display="flex"
            flexDirection={{ base: 'column', sm: 'row' }}
            justifyContent="center"
            width={{ lg: '1150px', base: '100%' }}
            m="0 auto 50px"
          >
            {testimonialdata[0]?.testimonials?.map((el, i) => {
              return (
                <Box
                  background="#FFFFFF"
                  padding={{ base: '35px 20px' }}
                  display="flex"
                  flexDirection={{ base: 'column', lg: 'row' }}
                  w={{ base: '90%', sm: '45%', lg: '50%' }}
                  m={{ base: '0 auto', sm: '0 0 0 15px' }}
                  fontFamily="OpenSansRegular"
                  fontSize="14px"
                  alignSelf={{ base: 'center', sm: 'unset' }}
                  _first={{
                    margin: '0',
                    display: { base: 'none', sm: 'inherit' },
                  }}
                  key={i}
                >
                  <Box
                    m={{ base: '0 0 20px 0', lg: '0 27px 0 0' }}
                    w={{ base: '', lg: '64%' }}
                    minWidth={{ base: 'unset', lg: '120px' }}
                    alignSelf={{ base: 'center', lg: 'unset' }}
                  >
                    <Image
                      loading="lazy"
                      src={el?.photo?.url}
                      title={el?.photo?.title}
                      alt={el?.photo?.alt}
                      borderRadius="50%"
                      width="120px"
                    />
                  </Box>
                  <Box color="#5A5A5A">
                    <Text fontFamily="OpenSansRegular" fontSize="16px">
                      {el?.testimonialText}
                    </Text>
                    <Text
                      color="#28231E"
                      fontFamily="MerriweatherBold"
                      m={{ base: '18px 0 0 0' }}
                    >
                      <Text fontWeight="bold" fontSize="16px">
                        {el?.userName}
                      </Text>
                      <Text as="span" color="#AF1F22" fontSize="14px">
                        {el?.userDescription}
                      </Text>
                    </Text>
                  </Box>
                </Box>
              );
            })}
          </Box>
          {region === 'in' ? (
            <>
              {testimonialdata[1]?.linkUrl != '' && (
                <Button
                  background="#CA4E2A"
                  border="none"
                  borderRadius="3px"
                  fontSize="16px"
                  _hover={{
                    border: 'none',
                    textDecor: 'none',
                    bg: '#000054',
                  }}
                  w={{ base: 'auto' }}
                  m="50px auto 0"
                  display="flex"
                  fontFamily="OpenSansRegular"
                >
                  <Link
                    href={testimonialdata[1]?.linkUrl + userRegion}
                    target="_blank"
                    textDecor="none"
                    p="18px 34px 18px 34px"
                    _hover={{ textDecoration: 'none' }}
                  >
                    {testimonialdata[1]?.buttonText}
                  </Link>
                </Button>
              )}
            </>
          ) : (
            <Box color="#000000" m="20px 10px" textAlign="center">
              {region != 'in' && (
                <>
                  {testimonialdata[1]?.linkUrl != '' && (
                    <Button
                      background="#CA4E2A"
                      border="none"
                      borderRadius="3px"
                      fontSize="16px"
                      _hover={{
                        border: 'none',
                        textDecor: 'none',
                        bg: '#000054',
                      }}
                      w={{ base: 'auto' }}
                      m="50px auto 0"
                      display="flex"
                      fontFamily="OpenSansRegular"
                    >
                      <Link
                        href={testimonialdata[1]?.linkUrl + userRegion}
                        target="_blank"
                        textDecor="none"
                        p="18px 34px 18px 34px"
                        _hover={{ textDecoration: 'none' }}
                      >
                        {testimonialdata[1]?.buttonText}
                      </Link>
                    </Button>
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      </>
    );
  };
  const Testimonialsection2 = pageData => {
    const testimonial = pageData?.find(el => el.sectionId == 'Testimonial_2');
    const testimonialdata = testimonial?.sectionContent;
    return (
      <>
        {testimonial != undefined && (
          <Box w={{ base: 'auto' }} m={{ base: '', lg: '0 auto' }}>
            <Box
              display="flex"
              flexDirection={{ base: 'column', sm: 'row' }}
              justifyContent="center"
              width={{ lg: '1150px', base: '100%' }}
              m="0 auto"
            >
              {testimonialdata[0]?.testimonials?.map((el, i) => {
                return (
                  <Box
                    background="#FFFFFF"
                    key={i}
                    padding={{ base: '35px 20px' }}
                    display="flex"
                    flexDirection={{ base: 'column', lg: 'row' }}
                    w={{ base: '90%', sm: '45%', lg: '50%' }}
                    m={{ base: '0 auto', sm: '0 0 0 15px' }}
                    fontFamily="OpenSansRegular"
                    fontSize="14px"
                    alignSelf={{ base: 'center', sm: 'unset' }}
                    _first={{
                      margin: '0',
                      display: { base: 'none', sm: 'inherit' },
                    }}
                  >
                    <Box
                      m={{ base: '0 0 20px 0', lg: '0 27px 0 0' }}
                      w={{ base: '', lg: '64%' }}
                      minWidth={{ base: 'unset', lg: '120px' }}
                      alignSelf={{ base: 'center', lg: 'unset' }}
                    >
                      <Image
                        src={el?.photo?.url}
                        title={el?.photo?.title}
                        alt={el?.photo?.alt}
                        borderRadius="50%"
                        width="120px"
                      />
                    </Box>
                    <Box color="#5A5A5A">
                      <Text fontFamily="OpenSansRegular" fontSize="16px">
                        {el?.testimonialText}
                      </Text>
                      <Text
                        color="#28231E"
                        fontFamily="MerriweatherBold"
                        m={{ base: '18px 0 0 0' }}
                      >
                        <Text fontWeight="bold" fontSize="16px">
                          {el?.userName}
                        </Text>
                        <Text as="span" color="#AF1F22" fontSize="14px">
                          {el?.userDescription}
                        </Text>
                      </Text>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </>
    );
  };
  const Fireupsection = pageData => {
    const fireup = pageData?.find(el => el.sectionId == 'Fire_up');
    const fireupdata = fireup?.sectionContent;
    return (
      <Box background="#FFEBC7">
        <Box
          w={{ base: 'auto', sm: 'auto', lg: '870px' }}
          m={{ base: '0 auto' }}
          textAlign="center"
        >
          <Box>
            <Text
              as="h2"
              color="#000000"
              fontFamily="MerriweatherBold"
              fontSize={{ base: '30px', lg: '36px' }}
              m={{ base: '40px auto 10px' }}
              w={{ base: '80%', lg: '65%', md: '65%' }}
              lineHeight="50px"
            >
              {fireupdata[0]?.titleText}
            </Text>
            <Image
              loading="lazy"
              src={`${config.imgPath}/d/46272/1647429933-shivang_separate_border.png`}
              alt="separate_border"
              title="separate_border"
              m={{ base: '0 0 25px 0' }}
            />
            <Box
              fontFamily="OpenSansRegular"
              width={{ lg: '70%', base: '100%' }}
              m="0 auto"
            >
              <Text>{fireupdata[1]?.text}</Text>
            </Box>
          </Box>
          <Box
            display={{ base: 'flex' }}
            m={{ base: '50px 0 40px 0' }}
            color="#000000"
            fontFamily="MerriweatherBold"
            fontSize={{ base: '12px', sm: '16px' }}
            flexDirection={{ base: 'column', sm: 'unset' }}
            className="shivanga-spruthi"
          >
            {fireupdata[2]?.simpleCards?.map((data, i) => {
              return (
                <Box
                  w={{ base: '100%', sm: '33%' }}
                  borderRight={{ base: 'unset', sm: 'solid 1px #BB9B4B' }}
                  borderBottom={{ base: 'solid 1px #BB9B4B', sm: 'unset' }}
                  p={{ base: '10px 30px', sm: '10px 0' }}
                  _last={{ borderRight: 'none' }}
                  display={{ base: 'flex', sm: 'unset' }}
                  alignItems="center"
                  key={i}
                >
                  {i == 2 ? (
                    <>
                      <a
                        href={fireupdata[2]?.title[0]?.titleSubtext}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LazyLoadImageComponent
                          src={data?.rectangularImage?.url}
                          alt={data?.rectangularImage?.alt}
                          title={data?.rectangularImage?.title}
                        />
                        {/* </a>
                      <a
                        href={fireupdata[2]?.title[0]?.titleSubtext}
                        target="_blank"
                      > */}
                        <Text
                          margin={{
                            base: '8px 15px 5px 10px',
                            sm: '10px 0 5px',
                          }}
                        >
                          {data?.title}
                        </Text>
                        {/* </a>
                      <a
                        href={fireupdata[2]?.title[0]?.titleSubtext}
                        target="_blank"
                      > */}
                        <Text fontWeight="bold">{data?.descriptionText}</Text>
                      </a>
                    </>
                  ) : (
                    <>
                      <LazyLoadImageComponent
                        src={data?.rectangularImage?.url}
                        alt={data?.rectangularImage?.alt}
                        title={data?.rectangularImage?.title}
                      />
                      <Text
                        margin={{ base: '8px 25px 5px 10px', sm: '10px 0 5px' }}
                      >
                        {data?.title}
                      </Text>
                      <Text fontWeight="bold">{data?.descriptionText}</Text>
                    </>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    );
  };
  const Contactsection = pageData => {
    const contact = pageData?.find(el => el.sectionId == 'Contact_us');
    const contactdata = contact?.sectionContent;
    return (
      <Box background="#FFEBC7" textAlign="center" p={{ base: '50px 0 0 0' }}>
        <Box>
          <Text
            as="h2"
            color="#000000"
            fontFamily="MerriweatherBold"
            fontSize={{ base: '30px', lg: '36px' }}
            m={{ base: '0 0 15px 0' }}
          >
            {contactdata[0]?.titleText}
          </Text>
          <Image
            loading="lazy"
            src={`${config.imgPath}/d/46272/1647429933-shivang_separate_border.png`}
            alt="separate_border"
            title="separate_border"
            // src="https://images.sadhguru.org/d/46272/1647429933-shivang_separate_border.png"
            m={{ base: '0 0 25px 0' }}
          />
        </Box>
        <Box
          fontFamily="OpenSansRegular"
          width={{ lg: '70%', base: '100%' }}
          m="0 auto"
          color="#262523"
          p={{ base: '20px 20px 18px', sm: '20px 0 18px' }}
        >
          {contactdata[1]?.text}
        </Box>
        <Box
          maxW={{ base: '100%', lg: '755px' }}
          m={{ base: '0 auto' }}
          background="#FFFFFF"
          color="#02093C"
          p={{ base: '25px 25px' }}
          display="flex"
          fontFamily="OpenSansRegular"
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems={{ base: 'center', sm: 'unset' }}
          className="shivanga-text contactdetail"
        >
          <Box
            w={{ base: '90%', lg: '80%', sm: '40%' }}
            className="contacttext"
          >
            {StructuredTextContent(contactdata[2]?.body)}
          </Box>
          <Box
            borderRight={{ base: 'unset', sm: 'solid 0.5px #000000' }}
            borderBottom={{ base: 'solid 0.5px #000000', sm: 'unset' }}
            w={{ base: '85%', sm: '1px' }}
            m={{ base: '25px 0', sm: '0 10%' }}
          ></Box>
          <Box w={{ base: '80%', sm: '40%' }}>
            {StructuredTextContent(contactdata[3]?.body)}
          </Box>
        </Box>
      </Box>
    );
  };
  const Faqsection = pageData => {
    const faq = pageData?.find(el => el.sectionId == 'FAQ');
    const faqData = pageData?.find(el => el.sectionId == 'FaqSection');
    return (
      <Box background="#02093C" textAlign="center" p={{ base: ' 0 0 40px 0' }}>
        <Text
          as="h2"
          color="#FFFFFF"
          fontFamily="MerriweatherBold"
          fontSize={{ base: '30px', lg: '36px' }}
          p={{ base: '70px 0 10px' }}
          w={{ base: '65%' }}
          m="0 auto"
        >
          {faq.sectionContent[0]?.titleText}
        </Text>
        <Image
          loading="lazy"
          src={`${config.imgPath}/d/46272/1647429933-shivang_separate_border.png`}
          alt="separate_border"
          title="separate_border"
          // src="https://images.sadhguru.org/d/46272/1647429933-shivang_separate_border.png"
          margin={{ base: '0 0 25px 0' }}
        />
        {faqData.sectionContent.map(data => {
          return (
            <Accordion
              allowToggle
              m={{ base: '0 auto', lg: '0 auto' }}
              w={{ base: '95%', lg: '870px' }}
            >
              <AccordionItem m="0 0 0 0" color="#FFFFFF" id="one" border="none">
                {({ isExpanded }) => (
                  <>
                    <h2>
                      <AccordionButton
                        p="14px 11.8% 14px 9%"
                        border="solid 0.6px #D2D2D2"
                        borderRadius="6px"
                        id="one-btn"
                        _focus={{ outline: '0' }}
                      >
                        <Box
                          flex="1"
                          textAlign="left"
                          fontFamily="OpenSansRegular"
                          fontSize="20px"
                          lineHeight="1.2"
                        >
                          {data?.title}
                        </Box>
                        <Image
                          loading="lazy"
                          src={`${config.imgPath}/d/46272/1648228642-faq-arrow-white.svg`}
                          alt="arrow"
                          title="arrow"
                          // src="https://images.sadhguru.org/d/46272/1648228642-faq-arrow-white.svg"
                          transform={
                            isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)'
                          }
                          w="15px"
                        />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      pb={4}
                      borderTop="solid 1px rgba(255, 255, 255, 0.3)"
                      p="0"
                      textAlign="left"
                      fontFamily="OpenSansRegular"
                      fontSize="16px"
                      id="one-ap"
                      w={{ base: '94%', sm: '85%', lg: '80%' }}
                      m="0 auto"
                    >
                      {data?.faqs.map(datachild => {
                        return (
                          <Accordion
                            allowToggle
                            m={{ base: '0 auto', lg: '0 auto' }}
                            w={{ base: '95%', lg: '100%' }}
                          >
                            <AccordionItem
                              m="0 0 0 0"
                              color="#C5C5C5"
                              id="one1"
                              border="none"
                            >
                              {({ isExpanded }) => (
                                <>
                                  <h2>
                                    <AccordionButton
                                      paddingTop="20px"
                                      paddingBottom="20px"
                                      border="none"
                                      borderRadius="6px"
                                      id="one-btn1"
                                      _focus={{ outline: '0' }}
                                    >
                                      <Box
                                        flex="1"
                                        textAlign="left"
                                        fontFamily="OpenSansRegular"
                                        fontSize="18px"
                                        lineHeight="1.2"
                                        fontWeight="bold"
                                      >
                                        {datachild?.question}
                                      </Box>
                                      <Image
                                        loading="lazy"
                                        alt="Arrow"
                                        title="Arrow"
                                        src={`${config.imgPath}/d/46272/1648228642-faq-arrow-white.svg`}
                                        transform={
                                          isExpanded
                                            ? 'rotate(-180deg)'
                                            : 'rotate(0deg)'
                                        }
                                        w="15px"
                                      />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel
                                    pb={4}
                                    borderTop="none"
                                    p="5px 20px 20px"
                                    textAlign="left"
                                    fontFamily="OpenSansRegular"
                                    fontSize="16px"
                                    id="one-ap1"
                                  >
                                    <Box>
                                      <Text
                                        borderBottom="solid 1px #A7A7A7"
                                        p="0 0 30px 0"
                                      >
                                        {StructuredTextContent(
                                          datachild?.answer
                                        )}
                                      </Text>
                                    </Box>
                                  </AccordionPanel>
                                </>
                              )}
                            </AccordionItem>
                          </Accordion>
                        );
                      })}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          );
        })}
      </Box>
    );
  };
  const Sadhanasection = pageData => {
    const sadhana = pageData?.find(el => el.sectionId == 'Sadhana_details');
    const sadhanaData = sadhana?.sectionContent;
    return (
      <Box background="#FFEBC7" p={{ base: '', sm: '5px 0 63px 0' }}>
        <Box maxW={{ base: 'unset', sm: '870px' }} m={{ base: '0 auto' }}>
          <Box textAlign="center">
            <Text
              as="h2"
              color="#000000"
              fontFamily="MerriweatherBold"
              fontSize={{ base: '30px', sm: '36px' }}
              m={{ base: '0 0 25px 0' }}
            >
              {sadhanaData[0]?.titleText}
            </Text>
            <Image
              loading="lazy"
              alt="separate_border"
              title="separate_border"
              src={`${config.imgPath}/d/46272/1647429933-shivang_separate_border.png`}
              m={{ base: '0 0 55px 0' }}
            />
          </Box>
          {/* Tabs */}
          <Box
            h={{ base: '69px' }}
            background="#FAE2AB"
            borderRadius="3px 3px 0 0"
            position={{ base: 'sticky', lg: 'sticky', md: 'sticky' }}
            top="0"
          >
            <Box>
              <UnorderedList
                listStyleType="none"
                display="flex"
                justifyContent="space-around"
                alignItems="end"
                h={{ base: '69px' }}
                color="#000000"
                fontSize="16px"
                fontFamily="OpenSansRegular"
                fontWeight="bold"
                id="sadhana-details-tabs"
                m="0"
              >
                <ListItem
                  borderBottom={{ base: 'solid 6px transparent' }}
                  w={{ base: '25%' }}
                  paddingBottom={{ base: '20px' }}
                  textAlign={{ base: 'center' }}
                  className="inactive active"
                  cursor="pointer"
                  onClick={tabToggle}
                  id="tab-1"
                >
                  {sadhanaData[1]?.data[0]?.Tab_title}
                </ListItem>
                <ListItem
                  borderBottom={{ base: 'solid 6px transparent' }}
                  w={{ base: '25%' }}
                  paddingBottom={{ base: '20px' }}
                  textAlign={{ base: 'center' }}
                  className="inactive"
                  cursor="pointer"
                  onClick={tabToggle}
                  id="tab-2"
                >
                  {sadhanaData[1]?.data[1]?.Tab_title}
                </ListItem>
                <ListItem
                  borderBottom={{ base: 'solid 6px transparent' }}
                  w={{ base: '25%' }}
                  paddingBottom={{ base: '20px' }}
                  textAlign={{ base: 'center' }}
                  className="inactive"
                  cursor="pointer"
                  onClick={tabToggle}
                  id="tab-3"
                >
                  {sadhanaData[1]?.data[2]?.Tab_title}
                </ListItem>
                <ListItem
                  borderBottom={{ base: 'solid 6px transparent' }}
                  w={{ base: '25%' }}
                  paddingBottom={{ base: '20px' }}
                  textAlign={{ base: 'center' }}
                  className="inactive"
                  cursor="pointer"
                  onClick={tabToggle}
                  id="tab-4"
                >
                  {sadhanaData[1]?.data[3]?.Tab_title}
                </ListItem>
              </UnorderedList>
            </Box>
          </Box>
          {/* Tab content */}
          <Box>
            <Box background="#FFF7E5" p={{ base: '43px 15px 40px' }}>
              <Text
                color="#000000"
                fontSize="16px"
                fontFamily="OpenSansRegular"
                fontWeight="bold"
                className="sadhanatext"
              >
                {StructuredTextContent(sadhanaData[2]?.body)}
              </Text>
              {/* Tab 1 */}
              <Box>
                <Box id="tab-content-1" className="tab-content">
                  <Box
                    m={{ base: '35px 0 0 0' }}
                    display="flex"
                    flexDirection={{ base: 'column', sm: 'row' }}
                  >
                    <a name="tab-1c" />
                    <Box
                      m={{ base: '0 0 40px 0', sm: 'unset' }}
                      w={{ base: '345px', sm: '239px' }}
                      h={{ base: '206px', sm: '143px' }}
                    >
                      <LazyLoadImageComponent
                        src={`${config.imgPath}/d/46272/1648101270-sadhana-guidelines.jpg`}
                        alt="sadhana-guidelines"
                        title="sadhana-guidelines"
                        // src="https://images.sadhguru.org/d/46272/1648101270-sadhana-guidelines.jpg"
                        w={{ base: '100%', sm: 'unset' }}
                      />
                    </Box>
                    <Box
                      display="flex"
                      margin="0 0 0 15px"
                      w={{ base: '100%', sm: '69%' }}
                      borderBottom="solid 1px #E4A100"
                      alignSelf="end"
                      padding={{ base: '0 0 7px 0' }}
                    >
                      <Text
                        as="h4"
                        color="#000000"
                        fontSize="22px"
                        fontFamily="MerriweatherBold"
                        fontWeight="bold"
                      >
                        {sadhanaData[3]?.titleText}
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    color="#000000"
                    fontSize="16px"
                    fontFamily="OpenSansRegular"
                    m={{
                      lg: '20px 0 0 30px',
                      md: '20px 0 0 30px',
                      sm: '20px 0 0 0',
                    }}
                    className="sadhana_list shivanga-text"
                  >
                    {parser(sadhanaData[4]?.htmlContent || '')}
                  </Box>
                </Box>
                <Box id="tab-content-2" className="tab-content">
                  <Box
                    m={{ base: '35px 0 0 0' }}
                    display="flex"
                    flexDirection={{ base: 'column', sm: 'row' }}
                  >
                    <a name="tab-2c" />
                    <Box
                      m={{ base: '0 0 40px 0', sm: 'unset' }}
                      w={{ base: '345px', sm: '239px' }}
                      h={{ base: '206px', sm: '143px' }}
                    >
                      <LazyLoadImageComponent
                        src={`${config.imgPath}/d/46272/1648101283-registration.jpg`}
                        alt="registration"
                        title="registration"
                        // src="https://images.sadhguru.org/d/46272/1648101283-registration.jpg"
                        width={{ base: '100%', sm: 'unset' }}
                      />
                    </Box>
                    <Box
                      display="flex"
                      margin="0 0 0 15px"
                      w={{ base: '100%', sm: '69%' }}
                      borderBottom="solid 1px #E4A100"
                      alignSelf="end"
                      padding={{ base: '0 0 7px 0' }}
                    >
                      <Text
                        as="h4"
                        color="#000000"
                        fontSize="22px"
                        fontFamily="MerriweatherBold"
                        fontWeight="bold"
                      >
                        {sadhanaData[5]?.titleText}
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    color="#000000"
                    fontSize="16px"
                    fontFamily="OpenSansRegular"
                    m={{
                      lg: '20px 0 0 30px',
                      md: '20px 0 0 30px',
                      sm: '20px 0 0 0',
                    }}
                    className="sadhana_list shivanga-text"
                  >
                    {parser(sadhanaData[6]?.htmlContent || '')}
                  </Box>
                </Box>
                <Box id="tab-content-3" className="tab-content">
                  <Box
                    m={{ base: '35px 0 0 0' }}
                    display="flex"
                    flexDirection={{ base: 'column', sm: 'row' }}
                  >
                    <a name="tab-3c" />
                    <Box
                      m={{ base: '0 0 40px 0', sm: 'unset' }}
                      w={{ base: '345px', sm: '239px' }}
                      h={{ base: '206px', sm: '143px' }}
                    >
                      <LazyLoadImageComponent
                        alt="initiation"
                        title="initiation"
                        src={`${config.imgPath}/d/46272/1648101313-shivanga-initiation.jpg`}
                        // src="https://images.sadhguru.org/d/46272/1648101313-shivanga-initiation.jpg"
                        width={{ base: '100%', sm: 'unset' }}
                      />
                    </Box>
                    <Box
                      display="flex"
                      margin="0 0 0 15px"
                      w={{ base: '100%', sm: '69%' }}
                      borderBottom="solid 1px #E4A100"
                      alignSelf="end"
                      padding={{ base: '0 0 7px 0' }}
                    >
                      <Text
                        as="h4"
                        color="#000000"
                        fontSize="22px"
                        fontFamily="MerriweatherBold"
                        fontWeight="bold"
                      >
                        {sadhanaData[7]?.titleText}
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    color="#000000"
                    fontSize="16px"
                    fontFamily="OpenSansRegular"
                    m={{
                      lg: '20px 0 0 30px',
                      md: '20px 0 0 30px',
                      sm: '20px 0 0 0',
                    }}
                    className="sadhana_list shivanga-text"
                  >
                    {parser(sadhanaData[8]?.htmlContent || '')}
                  </Box>
                </Box>
                <Box id="tab-content-4" className="tab-content">
                  <Box
                    m={{ base: '35px 0 0 0' }}
                    display="flex"
                    flexDirection={{ base: 'column', sm: 'row' }}
                  >
                    <a name="tab-4c" />
                    <Box
                      m={{ base: '0 0 40px 0', sm: 'unset' }}
                      w={{ base: '345px', sm: '239px' }}
                      h={{ base: '206px', sm: '143px' }}
                    >
                      <LazyLoadImageComponent
                        alt="culmination"
                        title="culmination"
                        src={`${config.imgPath}/d/46272/1648101349-culmination.jpg`}
                        // src="https://images.sadhguru.org/d/46272/1648101349-culmination.jpg"
                        width={{ base: '100%', sm: 'unset' }}
                      />
                    </Box>
                    <Box
                      display="flex"
                      margin="0 0 0 15px"
                      w={{ base: '100%', sm: '69%' }}
                      borderBottom="solid 1px #E4A100"
                      alignSelf="end"
                      padding={{ base: '0 0 7px 0' }}
                    >
                      <Text
                        as="h4"
                        color="#000000"
                        fontSize="22px"
                        fontFamily="MerriweatherBold"
                        fontWeight="bold"
                      >
                        {sadhanaData[9]?.titleText}
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    color="#000000"
                    fontSize="16px"
                    fontFamily="OpenSansRegular"
                    m={{
                      lg: '20px 0 0 30px',
                      md: '20px 0 0 30px',
                      sm: '20px 0 0 0',
                    }}
                    className="sadhana_list shivanga-text"
                  >
                    {parser(sadhanaData[10]?.htmlContent || '')}
                  </Box>
                </Box>
              </Box>
              {region === 'in' ? (
                <>
                  {sadhanaData[12]?.linkUrl != '' && (
                    <Button
                      background="#CA4E2A"
                      border="none"
                      borderRadius="3px"
                      fontSize="16px"
                      _hover={{
                        border: 'none',
                        textDecor: 'none',
                        bg: '#000054',
                      }}
                      w={{ base: 'auto' }}
                      m="40px auto 20px"
                      display="flex"
                      fontFamily="OpenSansRegular"
                    >
                      <Link
                        href={sadhanaData[12]?.linkUrl + userRegion}
                        target="_blank"
                        textDecor="none"
                        p="18px 34px 18px 34px"
                        _focus={{ outline: '0' }}
                        _hover={{ textDecoration: 'none' }}
                      >
                        {sadhanaData[12]?.buttonText}
                      </Link>
                    </Button>
                  )}
                </>
              ) : (
                <Box color="#000000" m="20px 10px" textAlign="center">
                  {region != 'in' && (
                    <>
                      {sadhanaData[12]?.linkUrl != '' && (
                        <Button
                          background="#CA4E2A"
                          border="none"
                          borderRadius="3px"
                          fontSize="16px"
                          _hover={{
                            border: 'none',
                            textDecor: 'none',
                            bg: '#000054',
                          }}
                          w={{ base: 'auto' }}
                          m="40px auto 20px"
                          display="flex"
                          fontFamily="OpenSansRegular"
                        >
                          <Link
                            href={sadhanaData[12]?.linkUrl + userRegion}
                            target="_blank"
                            textDecor="none"
                            p="18px 34px 18px 34px"
                            _focus={{ outline: '0' }}
                            _hover={{ textDecoration: 'none' }}
                          >
                            {sadhanaData[12]?.buttonText}
                          </Link>
                        </Button>
                      )}
                    </>
                  )}
                  {/* {sadhanaData[12]?.buttonText} */}
                </Box>
              )}
              {sadhanaData[13]?.text != '' && (
                <Box
                  fontStyle="italic"
                  fontSize="16px"
                  fontFamily="OpenSansRegular"
                  textAlign="center"
                  className="textcenter"
                >
                  {sadhanaData[13]?.text}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {/* Testimonials 2 */}
        <Box m="50px 0 20px 0">{Testimonialsection2(pageData)}</Box>
      </Box>
    );
  };
  const Nextstepsection = pageData => {
    const nextstep = pageData?.find(el => el.sectionId == 'Interested');
    const nextstepdata = nextstep.sectionContent;
    return (
      <Box textAlign="center" bgColor="#414141">
        <Box
          p={{ base: '60px 0 ' }}
          background={`url(${config.imgPath}/d/46272/1648035544-the-next-steps-bg.jpg) no-repeat 0 0 #414141`}
          // background="url(https://images.sadhguru.org/d/46272/1648035544-the-next-steps-bg.jpg) no-repeat 0 0 #414141"
          backgroundSize="cover"
        >
          <Box>
            <Text
              as="h2"
              color="#FFFFFF"
              fontFamily="MerriweatherBold"
              fontSize={{ base: '20px', sm: '40px' }}
              m={{ base: '0 0 15px 0' }}
            >
              {nextstepdata[0]?.title[0]?.titleText}
              <Text
                as="span"
                display={{ base: 'block' }}
                fontSize={{ base: '16px', sm: '30px' }}
              >
                {nextstepdata[0]?.title[0]?.titleSubtext}
              </Text>
            </Text>
            <Image
              alt="separate_border"
              title="separate_border"
              loading="lazy"
              src={`${config.imgPath}/d/46272/1647429933-shivang_separate_border.png`}
              // src="https://images.sadhguru.org/d/46272/1647429933-shivang_separate_border.png"
              m={{ base: '0 0 25px 0' }}
            />
          </Box>
          <Box
            display={{ base: 'flex' }}
            w={{ base: '93%', sm: '80%', lg: '920px' }}
            m={{ base: '50px auto 10px' }}
            flexDirection={{ base: 'column', sm: 'row' }}
          >
            {nextstepdata[0]?.simpleCards.map((data, i) => {
              return (
                <Box
                  background="#EBE3D5"
                  w={{ base: '100%', sm: '24%' }}
                  m={{ base: '0 1% 60px 0', sm: '0 1% 0 0' }}
                  _last={{ m: '0' }}
                  key={i}
                >
                  <Box
                    background="#ECB11B"
                    w={{ base: '60px' }}
                    h={{ base: '60px' }}
                    lineHeight="60px"
                    fontWeight="bold"
                    borderRadius="45px"
                    fontSize={{ base: '40px', lg: '25px' }}
                    position="relative"
                    top="-32px"
                    m={{ base: '0 auto' }}
                  >
                    <Text color="#000000">{i + 1}</Text>
                  </Box>
                  <Text
                    color="#000000"
                    fontSize={{ base: '20px' }}
                    fontWeight="bold"
                    fontFamily="MerriweatherBold"
                    m={{ base: '-20px 0 0 0' }}
                  >
                    {data.title}
                  </Text>
                  <Text
                    color="#615337"
                    fontSize={{ base: '16px' }}
                    fontFamily="OpenSansRegular"
                    p={{ base: '10px 20px 30px 20px' }}
                  >
                    {data.descriptionText}
                  </Text>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    );
  };
  const Upcomingsection = pageData => {
    const upcoming = pageData?.find(el => el.sectionId == 'Upcoming_Dates');
    const upcomingdata = upcoming.sectionContent;
    return (
      <Box background="#02093C">
        <Box
          maxW={{ base: 'unset', lg: '870px' }}
          m={{ base: '0 auto' }}
          p={{ base: '40px 0', sm: '80px 0 40px' }}
        >
          <Box textAlign="center">
            <Text
              as="h2"
              color="#FFFFFF"
              fontFamily="MerriweatherBold"
              fontSize={{ base: '30px', sm: '36px' }}
              m={{ base: '0 0 15px 0' }}
            >
              {upcomingdata[0]?.titleText}
            </Text>
            <Image
              loading="lazy"
              alt="separate_border"
              title="separate_border"
              src={`${config.imgPath}/d/46272/1647429933-shivang_separate_border.png`}
              // src="https://images.sadhguru.org/d/46272/1647429933-shivang_separate_border.png"
              m={{ base: '0 0 25px 0' }}
            />
            <Box width={{ lg: '80%', base: '100%' }} m="20px auto">
              <Text
                color="#C3C6D8"
                fontFamily="OpenSansRegular"
                fontSize="18px"
              >
                {StructuredTextContent(upcomingdata[1]?.body)}
              </Text>
            </Box>
          </Box>
          <Box className="upcomingdate_data">
            <Box
              display={{ base: 'flex' }}
              textAlign="center"
              color="#FFFFFF"
              fontFamily="MerriweatherBold"
              fontSize={{ lg: '18px', md: '16px', base: '14px' }}
              fontWeight="bold"
              p={{ base: '25px 0 5px' }}
              className="upcomingdate_title"
            >
              <Box w="33%">{upcomingdata[2].data[0].Title1}</Box>
              <Box w="33%">{upcomingdata[2].data[0].Title2}</Box>
              <Box w="33%">{parser(upcomingdata[2].data[0].Title3)}</Box>
            </Box>
            {upcomingdata[2].data.map((el, index) => {
              return (
                <>
                  {index > '0' && (
                    <Box
                      display={{ base: 'flex' }}
                      textAlign="center"
                      p={{ base: '18px 0' }}
                      fontFamily="OpenSansRegular"
                      fontSize={{ lg: '18px', md: '16px', base: '14px' }}
                      fontWeight="bold"
                      background={{ base: '#EBE3D5' }}
                      _even={{ background: '#FFF7E9' }}
                    >
                      <Box w="33%">{parser(el.Col1 || '')}</Box>
                      <Box w="33%">{parser(el.Col2 || '')}</Box>
                      <Box w="33%">{parser(el.Col3 || '')}</Box>
                    </Box>
                  )}
                </>
              );
            })}
          </Box>
        </Box>
        {region === 'in' ? (
          <>
            {upcomingdata[3].linkUrl != '' && (
              <Button
                background="#CA4E2A"
                border="none"
                borderRadius="3px"
                fontSize="16px"
                p="18px 34px 18px 34px"
                _hover={{
                  border: 'none',
                  textDecor: 'none',
                }}
                w={{ base: 'auto' }}
                m="0px auto 20px"
                display="flex"
                fontFamily="OpenSansRegular"
              >
                <Link
                  href={upcomingdata[3].linkUrl + userRegion}
                  target="_blank"
                  textDecor="none"
                  _focus={{ outline: '0' }}
                  _hover={{ textDecoration: 'none' }}
                >
                  {upcomingdata[3].buttonText}
                </Link>
              </Button>
            )}
          </>
        ) : (
          <Box color="#ffffff" m="20px 10px" textAlign="center">
            {region != 'in' && (
              <>
                {upcomingdata[3].linkUrl != '' && (
                  <Button
                    background="#CA4E2A"
                    border="none"
                    borderRadius="3px"
                    fontSize="16px"
                    p="18px 34px 18px 34px"
                    _hover={{
                      border: 'none',
                      textDecor: 'none',
                    }}
                    w={{ base: 'auto' }}
                    m="0px auto 20px"
                    display="flex"
                    fontFamily="OpenSansRegular"
                  >
                    <Link
                      href={upcomingdata[3].linkUrl + userRegion}
                      target="_blank"
                      textDecor="none"
                      _focus={{ outline: '0' }}
                      _hover={{ textDecoration: 'none' }}
                    >
                      {upcomingdata[3].buttonText}
                    </Link>
                  </Button>
                )}
              </>
            )}
            {/* {upcomingdata[3].buttonText} */}
          </Box>
        )}
        <Text
          fontStyle="italic"
          fontSize="16px"
          fontFamily="OpenSansRegular"
          textAlign="center"
          color="#FFFFFF"
          p={{ base: '20px 0 40px' }}
          className="upcomedetail"
        >
          {StructuredTextContent(upcomingdata[4]?.body)}
        </Text>
      </Box>
    );
  };
  const authContext = useContext(AuthContext);
  const myRef = useRef(null);

  useEffect(() => {
    const currentRegion = config.locales?.find(
      locale => locale.region_sf === region
    ).region;
    authContext.RegionAndLang(region, language);
    setCurrentUserRegion(currentRegion);
    const currentUrl = window.location.hash;
    // if (currentUrl.indexOf('#meet-sadhguru') >= 0) {
    //   myRef.current.scrollIntoView();
    // }
  }, [authContext, region, language]);

  const checkLanguage = language => {
    let regionLangauge = false;
    // if (language === 'ta' || language === 'te' || language === 'ml' || language === 'hi' || language === 'kn') {
    //   regionLangauge = true;
    // } else {
    //   regionLangauge = false;
    // }
    return (
      <>
        {regionLangauge && (
          <Box m="0 auto" className="clear">
            {Banner(pageDetailData)}
            {/* What is Shivanga Sadhana: section */}
            {WhatisShivanga(pageDetailData)}
            <Box background="#FFEBC7" p={{ base: '', sm: '23px 0 63px 0' }}>
              {/* Testimonials 1 */}
              {Testimonialsection(pageDetailData)}
              {/* Benfits of Shivanga */}
              {Benefits(pageDetailData)}
              {/* Sadhana Details */}
              {Sadhanasection(pageDetailData)}
              {/* Upcoming Dates */}
              {Upcomingsection(pageDetailData)}
              {/* The next steps */}
              {Nextstepsection(pageDetailData)}
              {/* Fire up your devotion */}
              {Fireupsection(pageDetailData)}
              {/* FAQ */}
              {Faqsection(pageDetailData)}
              {/* Contact us */}
              {Contactsection(pageDetailData)}
              {region}
              {language}
            </Box>
          </Box>
        )}
        {!regionLangauge && (
          <Box m="0 auto" className="clear">
            <ShivangaBanner
              sectionContent={pageData}
              region={region}
              language={language}
              userRegion={userRegion}
            />
            <WhatsShivanga
              sectionContent={pageData}
              region={region}
              language={language}
            />
            <SignificanceVellagiri
              sectionContent={pageData}
              region={region}
              language={language}
            />
            <Box background="#FFEBC7" p={{ base: '0', sm: '23px 0 63px 0' }}>
              <HighlightsContent
                sectionContent={pageData}
                region={region}
                language={language}
              />
              <ShivangaBenefits
                sectionContent={pageData}
                region={region}
                language={language}
              />
              <ShivangaRegistration
                sectionContent={pageData}
                region={region}
                language={language}
                userRegion={userRegion}
              />
              <SadhanaContent
                sectionContent={pageData}
                region={region}
                language={language}
              />
              <ShivangaTestimonials
                sectionContent={pageData}
                region={region}
                language={language}
              />
              <Fireup
                sectionContent={pageData}
                region={region}
                language={language}
              />
              <ShivangaFaq
                sectionContent={pageData}
                region={region}
                language={language}
              />
              <ShivangaContactus
                sectionContent={pageData}
                region={region}
                language={language}
              />
              <ShivangaStickyButton
                sectionContent={pageData}
                region={region}
                language={language}
              />
            </Box>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <HeadComponent
        data={pageData._seoMetaTags}
        availableLocals={pageData.availableLocales}
      ></HeadComponent>
      {/* Back to home link */}
      <Box
        height={{ base: '42px' }}
        background="#161009"
        w="100%"
        p={{ base: '0 1.7%', sm: '0 6.7%' }}
        color="#FFFFFF"
        fontFamily="FedraSansStd-book"
        fontSize="12px"
      >
        <Box
          p={{ base: '12px 0 0 16px' }}
          width={{ lg: '1170px', base: '100%' }}
          ml={{ lg: 'calc((100% - 1200px) / 2)' }}
        >
          <Link href={`/${region}/${language}/`} _focus={{ outline: '0' }}>
            <Image
              loading="lazy"
              alt="Arrow"
              title="Arrow"
              src={`${config.staticPath}/d/46272/1648229215-back-line-arrow.svg`}
            />
            <Text as="span" m="0 0 0 4px">
              Back to Home page
            </Text>
          </Link>
        </Box>
      </Box>
      {checkLanguage(language)}
      <style global jsx>{`
        .event-text a {
          color: #cf4824;
        }
        .inactive {
          color: #595959;
        }
        .active {
          border-color: #ce8200 !important;
          color: #000000;
        }
        body {
          overflow-x: hidden;
        }
        .contactdetail a,
        .contactdetail .contacttext mark,
        .upcomedetail mark,
        .sadhanatext mark,
        .sadhana_download mark,
        #tab-content-1 .tab-content mark,
        .sadhana_list mark,
        .sadhana_list a {
          color: #ca4e2a;
          background: transparent;
          text-decoration: none;
        }
        #tab-content-1 .chakra-collapse {
          height: auto !important;
        }
        .sadhana_download a {
          color: #ca4e2a;
          text-decoration: none;
        }
        .upcomedetail p {
          font-style: normal;
        }
        .bannerparatext strong {
          color: #ffffff;
        }
        .testimonials-slider .slick-slider .slick-list {
          overflow: visible;
        }
        .testimonials-slider .slick-slider,
        .textcenter {
          text-align: center;
        }
        .testimonials-slider .slick-slider .slick-prev,
        .testimonials-slider .slick-slider .slick-next {
          background: url('${config.imgPath}/d/46272/1648229348-top-arrow.svg')
            no-repeat;
          transform: rotate(267deg);
          width: 50px;
          height: 50px;
          background-size: 100%;
          top: 30%;
          left: -10px;
        }
        .testimonials-slider .slick-slider .slick-next {
          transform: rotate(90deg);
          right: -10px;
          left: unset;
        }
        .testimonials-slider .slick-slider .slick-prev:before,
        .testimonials-slider .slick-slider .slick-next:before {
          display: none;
        }
        .testimonials-slider .slick-slider .slick-prev,
        .testimonials-slider .slick-slider .slick-next {
          z-index: 2;
        }
        .testimonials-slider .slick-slider .slick-slide {
          opacity: 0.5;
        }
        .testimonials-slider .slick-slider .slick-current {
          opacity: 1;
        }
        .clear {
          clear: both;
        }
        .langopt-structure a {
          color: #cf4824;
        }
        .buttonlink:hover {
          text-decoration: none;
        }
        .loginlink a {
          border-bottom: 1px solid #ffbd31;
          color: #ffbd31;
        }
        .sadhana_list ul {
          margin: 20px 0 0 30px;
        }
        .sadhana_list li {
          padding: 10px 0;
        }
        .upcomingdate_title span {
          font-size: 12px;
          font-weight: normal;
        }
        .upcomingdate_data span {
          display: block;
        }
        .whatisshivanga mark {
          margin: 16px 0 0 0;
          background: transparent;
          color: #ffffff;
        }
        .whatisdata a {
          text-decoration: none;
          color: #ca4e2a;
          font-weight: bold;
        }
        .shivanga-text a:hover span {
          background-color: #cf4824;
          color: white;
          background-image: linear-gradient(120deg, #cf4824, #cf4824);
          background-size: 100% 100%;
          transition: all 0.3s ease-in-out;
        }
        .shivanga-text a:hover span {
          color: white;
          text-decoration: underline;
        }
        @media screen and (max-width: 768px) {
          .testimonials-slider .slick-slider .slick-prev,
          .testimonials-slider .slick-slider .slick-next {
            width: 40px;
            height: 40px;
          }
          .testimonials-slider .slick-slider .slick-prev {
            left: 2px;
          }
          .testimonials-slider .slick-slider .slick-next {
            right: 2px;
          }
        }
      `}</style>
    </>
  );
}
export const getStaticPaths = async () => {
  const shivangaLandingPath = config.USE_LOCAL_URL
    ? [
        '/in/en/yoga-meditation/additional-programs/shivanga-sadhana',
        '/in/ta/yoga-meditation/additional-programs/shivanga-sadhana',
        '/in/te/yoga-meditation/additional-programs/shivanga-sadhana',
        '/us/en/yoga-meditation/additional-programs/shivanga-sadhana',
        '/ca/en/yoga-meditation/additional-programs/shivanga-sadhana',
      ]
    : await getLandingPaths(
        'L2',
        '/yoga-meditation/additional-programs/shivanga-sadhana',
        ['/yoga-meditation/additional-programs/shivanga-sadhana-pc'],
        'landing_page_child'
      );
  return {
    paths: shivangaLandingPath,
    fallback: false,
  };
};

export const getStaticProps = async ({ params: { region, language }, req }) => {
  const headerTopMenuData = await fetchHeaderTopMenuData(
    getLocale(region, language),
    'isotopmenu'
  );
  const headerMenuData = await fetchHeaderMenuData(
    getLocale(region, language),
    'isomainmenu'
  );
  const footerData = await fetchFooterData(
    getLocale(region, language),
    'isofooter'
  );
  const pageData = await fetchShivangaPageData(region, language);

  return {
    props: {
      headerTopMenuData: headerTopMenuData?.data || null,
      headerMenuData: headerMenuData?.data || null,
      region,
      language,
      footerData: footerData?.data || null,
      pageData: pageData || null,
      page: 'Shivanga',
    },
  };
};
